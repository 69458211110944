<template>
<div>
  <div class="panel p-2">
    <div class="flex items-center justify-between w-full mb-5">
      <div class="flex flex-col">
        <div><label><b>{{ provider && provider.id ? 'Editar' : 'Crear' }} {{ $t('provider') }} </b></label></div>
        <div></div>
      </div>
      <div>
        <b-tooltip :label="$t('close')" position="is-left">
          <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
            <b-icon pack="fas" icon="times" size="is-small" />
          </div>
        </b-tooltip>
      </div>
    </div>

    <div class="w-full pb-3">
      <div class="mt-4">
        <l-form-input
          v-model="form.code"
          ref="code"
          :form="form"
          field="code"
          label="code"
        />
      </div>

      <div class="mt-4">
        <l-form-input ref="name"
          :form="form"
          field="name"
          label="name"
        />
      </div>

      <div class="mt-4">
        <l-form-input ref="cif"
          :form="form"
          field="cif"
          label="cif"
        />
      </div>
    </div>

    <div class="flex items-center justify-end w-full">
      <form @submit.prevent="sendData" @keydown="form.onKeydown($event)" v-if="!processingData"
        class="w-full flex items-center justify-end mb-2 mt-2">
        <v-button :disabled="disableSaveButton" type="submit" icon="thumbs-up" icon-pack="far" class="mr-4">{{ $t('save') }}</v-button>
        <v-button color="grey" icon="undo-alt" @click="onCloseClicked">{{ $t('cancel') }}</v-button>
      </form>

      <v-button-processing v-if="processingData" />
    </div>
  </div>
</div>
</template>

<script>
import Form from "vform";
import axios from "axios";

export default {
  name: "provider-edit",

  components: {
  },

  props: {
    projectId: {type: Number, required: true },
    provider: { type: Object, default: null },
    companyId: { type: Number, default: null },
  },

  data() {
    return {
      disableSaveButton: false,
      processingData: false,

      form: new Form({  // data provider
        id: this.provider ? this.provider.id : null,
        code: this.provider ? this.provider.code : '',
        name: this.provider ? this.provider.name : '',
        cif: this.provider ? this.provider.cif : '',
        company_id: this.companyId,
      }),

    }
  },

  methods: {
    async getProviderData() {
      const { data } = await axios.get('/api/v2/providers/' + this.form.id, {params: { with_statuses: 1 }})
      if (data && data.success) {
        this.form.id = data.data.id
        this.form.code = data.data.code
        this.form.name = data.data.name
        this.form.cif = data.data.cif
      } else {
        this.$notify.error('error_loading_measurement_data')
      }
    },

    async sendData() {
      this.processingData = true

      var url = `/api/v2/providers`;
      let isCreate = ! this.form.id // si id es null o cero es crear el grupo
      if( !isCreate )
        url = `/api/v2/providers/${this.form.id}`; // se edita el grupo

      const { data } = await this.form.post(url).catch((error) => { this.processingData = false })

      if (data && data.success) {

        this.$notify.success('save_successful')

        if( data.warning )
          this.$notify.warning(data.warning)

        this.processingData = false
        this.$emit('epc-success-creating', this.entity)

      } else {
        this.processingData = false
        if( data && data.message )
          this.$notify.error(data.message)
        else
          this.$notify.error('error_creating_group')
      }
    },

    onCloseClicked() {
      this.measurement = null;

      this.$emit('epc-close', true)
    },

    handleEditEntity(entity) {

    },
  },
};
</script>

