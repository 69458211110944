<template>
  <div>
    <div class="top-bar flex justify-end">
      <v-button
        @click="addRow"
        icon="plus"
      >{{ $t('add_generic') }}</v-button>
    </div>
    <div class="top-bar flex justify-end">
      <v-button
        @click="showFormImportEntities = !showFormImportEntities"
        icon="plus"
      >{{ $t('import_issue_types') }}</v-button>
    </div>
    <div>
      <!-- Tabla tipos de  incidencias -->
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeInUp"
        leave-active-class="animated fadeOutDown"
      >
        <div class="panel">

          <!-- form create new -->
          <div class="w-full flex flex-wrap" v-show="create!=false || form.code">
            <div class="panel">
              <div v-if="option=='types'">
                <div  class="flex w-full flex-col lg:flex-row items-start">
                  <!-- titulo Tipo de incidencia  suministroo-->
                  <div class="w-full lg:w-1/3 lg:mr-1">
                    <span class="label">suministro</span>
                  </div>
                  <!-- switch is supply -->

                </div>
                <div v-if="!parent_id"
                 class="w-full lg:w-1/3 lg:mr-1">
                    <b-field>
                      <b-switch
                        v-model="isSupply"
                        type="is-success"
                      >
                        {{ $t(isSupply ? 'yes' : 'no') }}
                      </b-switch>
                    </b-field>
                  </div>
              </div>
              <form @submit.prevent="changeRow" class="w-full flex">
                <div class="flex-grow pr-3">
                  <label for="code" class="w-full">{{ $t('code') }}</label>
                  <v-input
                    v-model="form.code"
                    title="code"
                    id="code"
                    name="code"
                    placeholder="place_placeholder"
                    class="w-full"
                    required
                  />
                </div>
                <div class="flex-grow pr-3">
                  <label for="name" class="w-full">{{ $t('name') }}</label>
                  <v-input v-if="isSupply"
                    v-model="form.name"
                    title="name"
                    id="name"
                    name="name"
                    placeholder="name"
                    class="w-full"
                    value="suministro"
                    default-value="suministro"
                    disabled
                    required
                  />
                  <v-input v-else
                    v-model="form.name"
                    title="name"
                    id="name"
                    name="name"
                    placeholder="name"
                    class="w-full"
                    required
                  />
                </div>
                <div class="flex-grow pr-3">
                  <label for="description" class="w-full">{{ $t('description') }}</label>
                  <v-input
                    v-model="form.description"
                    title="description"
                    id="description"
                    name="description"
                    placeholder="description"
                    class="w-full"
                  />
                </div>

                <div class="flex-grow-0 pr-3 inline-flex self-end">
                  <v-button
                    native-type="submit"
                    :disabled="!form.code && !form.name"
                  >{{ $t('save') }}</v-button>
                </div>
              </form>
            </div>
          </div>

          <!-- add provider -->
          <div class="w-full flex flex-wrap" v-show="isAddProvider">
            <div class="panel">
              <form class="w-full flex"
                @submit.prevent="saveNewProvider"
              >
                <div class="flex-grow pr-3">
                  <label for="id" class="w-full">{{ $t('code') }}</label>
                  <b-select
                    v-model="formProvider.id"
                    placeholder="Selecciona un proveedor por código"
                    class="w-full"
                    @change="updateFormProviderByName"
                    required
                  >
                    <option
                      v-for="item in storeProviders"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.code }}
                    </option>
                  </b-select>
                </div>
                <div class="flex-grow pr-3">
                  <label for="name" class="w-full">{{ $t('name') }}</label>
                  <b-select
                    v-model="formProvider.id"
                    placeholder="Selecciona un proveedor por nombre"
                    class="w-full"
                    @change="updateFormProviderByName"
                    required
                  >
                    <option
                      v-for="item in storeProviders"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </b-select>

                </div>
                <div class="flex-grow-0 pr-3 inline-flex self-end">
                      <v-button
                      native-type="submit"
                      :disabled="!formProvider.id"


                      >{{ $t('save') }}</v-button>
                </div>
              </form>
            </div>
          </div>

          <!-- import entities -->

          <div class="w-full flex flex-wrap" v-show="showFormImportEntities">
            <div class="panel">
              <div class="flex items-center justify-between w-full mb-5">
                <div><label><b>{{ $t('import_issue_types') }}</b></label></div>
                <div>
                  <b-tooltip :label="$t('close')" position="is-left">
                    <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="showFormImportEntities = false">
                      <b-icon pack="fas" icon="times" size="is-small" />
                    </div>
                  </b-tooltip>
                </div>
              </div>
              <div class="w-full pb-3">
                <import-entities
                  :can-overwrite="true"
                  :project-id="projectId"
                  :withHistory="false"
                  entity-type="issue-type"
                  @success="$emit('epc-success-import')"
                />
              </div>
            </div>
          </div>

          <section>
            <b-table
              show-empty
              icon-pack="fas"
              :data="data"
              :loading="isLoading"
              paginated
              backend-pagination
              :per-page="perPage"
              :total="total"
              @page-change="onPageChange"
              backend-sorting
              :default-sort="orderField"
              :default-sort-direction="orderDir"
              @sort="onSort"
              striped
              bordered
              detailed
            >
              <template v-slot:empty="">
                <h4>{{ $t('no_rows') }}</h4>
              </template>

              <template>
                <b-table-column field="code" sortable width="200">
                  <template v-slot:header="">
                    {{ $t('code') }}
                  </template>
                  <template v-slot="props">
                    <div v-if="newData && newData.id == props.row.id">
                      <v-input
                        v-model="newData.code"
                        title="code"
                        id="code"
                        name="code"
                        :value="props.row.code"
                        placeholder="code"
                        class="w-full"
                        :ref="'fieldCode' + props.row.id"
                      />
                    </div>
                    <div v-else>
                      {{ props.row.code }}
                    </div>
                  </template>
                </b-table-column>

                <b-table-column field="name" >
                  <template v-slot:header="">
                    {{ $t('name') }}
                  </template>
                  <template v-slot="props">
                    <div v-if="newData && newData.id == props.row.id">
                      <v-input
                        v-model="newData.name"
                        title="name"
                        id="name"
                        name="name"
                        :value="props.row.name"
                        placeholder="name"
                        class="w-full"
                        :ref="'fieldName' + props.row.id"
                      />
                    </div>
                    <div v-else>
                      {{ props.row.name }}
                    </div>
                  </template>
                </b-table-column>

                <b-table-column field="description" >
                  <template v-slot:header="">
                    {{ $t('description') }}
                  </template>
                  <template v-slot="props">
                    <div v-if="newData && newData.id == props.row.id">
                      <v-input
                        v-model="newData.description"
                        title="description"
                        id="description"
                        name="description"
                        :value="props.row.description"
                        placeholder="description"
                        class="w-full"
                      />
                    </div>
                    <div v-else>
                      {{ props.row.description }}
                    </div>
                  </template>
                </b-table-column>


                <b-table-column field="actions" centered width="120">
                  <template v-slot:header="">
                    {{ $t('actions') }}
                  </template>
                  <template v-slot="props">
                    <div class="w-full h-full flex items-center justify-around">
                      <div v-if="newData && newData.id == props.row.id">
                        <b-tooltip :label="$t('save')" position="is-left">
                          <div @click="saveType()" class="cursor-pointer flex text-blue">
                            <b-icon pack="fas" icon="save" />
                          </div>
                        </b-tooltip>
                        <b-tooltip
                          :label="$t('cancel')"
                          type="is-danger"
                          position="is-left"
                        >
                          <div @click="() => { newData = {} }" class="cursor-pointer flex text-red">
                            <b-icon pack="fas" icon="ban" />
                          </div>
                        </b-tooltip>
                      </div>

                      <div v-else>
                        <b-tooltip
                          v-if="props.row.name == 'suministro' && !create
                          " position="is-left"
                          :label="$t('add_issue_type')"
                        >
                          <div @click="addRow(props.row)" class="cursor-pointer flex text-blue">
                            <b-icon pack="fas" icon="plus" />
                          </div>
                        </b-tooltip>
                        <b-tooltip
                          v-if="create && parent_id == props.row.id"
                          :label="$t('cancel')"
                          position="is-left"
                        >
                          <div @click="addRow()" class="cursor-pointer flex text-red">
                            <b-icon pack="fas" icon="ban" />
                          </div>
                        </b-tooltip>
                        <!--

                        -->
                        <b-tooltip :label="$t('edit')" position="is-left">
                          <div @click="editRow(props.row)" class="cursor-pointer flex text-blue">
                            <b-icon pack="fas" icon="pencil-alt" />
                          </div>
                        </b-tooltip>

                        <b-tooltip
                          v-if="canDelete(props.row)"
                          :label="$t('measurement_unit_has_usage')"
                          position="is-left"
                        >
                          <div class="cursor-not-allowed flex text-grey">
                            <b-icon pack="fas" icon="trash-alt" />
                          </div>
                        </b-tooltip>

                        <b-tooltip
                          v-else
                          :label="$t('delete')"
                          type="is-danger"
                          position="is-left"
                        >
                          <div @click="removeRow(props.row)" class="cursor-pointer flex text-red">
                            <b-icon pack="fas" icon="trash-alt" />
                          </div>
                        </b-tooltip>
                      </div>

                    </div>
                  </template>
                </b-table-column>

              </template>
              <template slot="detail" slot-scope="props">
                <div class="mb-1" v-if="props.row">
                  <b-collapse
                  class="card"
                  animation="slide"
                  aria-id="content"
                  >
                  <template #trigger="prop">
                    <div
                    class="card-header"
                    role="button"
                    aria-controls="content"
                    :aria-expanded="prop.open"
                    >
                      <div class="card-header-title">
                        <h4>
                          <b> {{ props.row.name=='suministro'? props.row.children.length: 0 }} {{ $t('types') }}
                          </b>
                        </h4>
                      </div>
                    </div>
                  </template>
                  <div class="card-content p-0">
                    <div class="content">
                      <template v-if="props.row.children.length > 0">
                        <b-table
                          show-empty
                          icon-pack="fas"
                          :data="props.row.children"
                          :loading="isLoading"
                          backend-sorting
                          :default-sort="orderField"
                          :default-sort-direction="orderDir"
                          striped
                          bordered
                          detailed
                        >
                        <template>
                          <b-table-column field="planning_code" label="P" cell-class="w-8 vertical-center" centered sortable>
                            <template v-slot:header="">
                              {{ $t('code') }}
                            </template>
                            <template v-slot="props">
                              {{ props.row.code }}
                            </template>
                          </b-table-column>
                          <b-table-column field="name" label="Nombre" cell-class="w-8 vertical-center" centered sortable>
                            <template v-slot:header="">
                              {{ $t('name') }}
                            </template>
                            <template v-slot="props">
                              {{ props.row.name }}
                            </template>
                          </b-table-column>
                          <b-table-column field="description" label="Descripción" cell-class="w-8 vertical-center" centered sortable>
                            <template v-slot:header="">
                              {{ $t('description') }}
                            </template>
                            <template v-slot="props">
                              {{ props.row.description }}
                            </template>
                          </b-table-column>
                          <b-table-column field="actionsClid" centered width="120">
                            <template v-slot:header="">
                              {{ $t('actions') }}
                            </template>
                            <template v-slot="props">
                              <div class="w-full h-full flex items-center justify-around">
                                <b-tooltip
                                  v-if="props.row.parent_id != null && !isAddProvider
                                  " position="is-left"
                                  :label="$t('add_issue_type')"
                                >
                                  <div @click="addProvider(props.row)" class="cursor-pointer flex text-blue">
                                    <b-icon pack="fas" icon="plus" />
                                  </div>
                                </b-tooltip>
                                <b-tooltip
                                  v-if="isAddProvider && currentTypeAddProviderId == props.row.id"
                                  :label="$t('cancel')"
                                  position="is-left"
                                >
                                  <div @click="cancelAdProvider" class="cursor-pointer flex text-red">
                                    <b-icon pack="fas" icon="ban" />
                                  </div>
                                </b-tooltip>
                                <b-tooltip :label="$t('edit')" position="is-left">
                                  <div @click="editRow(props.row)" class="cursor-pointer flex text-blue">
                                    <b-icon pack="fas" icon="pencil-alt" />
                                  </div>
                                </b-tooltip>
                                <b-tooltip
                                  v-if="canDelete(props.row)"
                                  :label="$t('measurement_unit_has_usage')"
                                  position="is-left"
                                >
                                  <div class="cursor-not-allowed flex text-grey">
                                    <b-icon pack="fas" icon="trash-alt" />
                                  </div>
                                </b-tooltip>
                                <b-tooltip
                                  v-else
                                  :label="$t('delete')"
                                  type="is-danger"
                                  position="is-left"
                                >
                                  <div @click="removeRow(props.row)" class="cursor-pointer flex text-red">
                                    <b-icon pack="fas" icon="trash-alt" />
                                  </div>
                                </b-tooltip>
                              </div>
                            </template>
                          </b-table-column>

                        </template>
                        <template slot="detail" slot-scope="props">
                          <div class="mb-1" v-if="props.row">
                            <b-collapse
                            class="card"
                            animation="slide"
                            aria-id="content"
                            >
                            <template #trigger="prop">
                              <div
                              class="card-header"
                              role="button"
                              aria-controls="content"
                              :aria-expanded="prop.open"
                              >
                                <div class="card-header-title">
                                  <h4>
                                    <b> {{ props.row.providers? props.row.providers.length: 0 }} {{ $t('providers') }}
                                    </b>
                                  </h4>
                                </div>
                              </div>
                            </template>
                            <div class="card-content p-0">
                              <div class="content">
                                <template v-if="props.row.providers.length > 0">
                                  <b-table
                                    show-empty
                                    icon-pack="fas"
                                    :data="props.row.providers? props.row.providers: []"
                                    :loading="isLoading"
                                    backend-sorting
                                    :default-sort="orderField"
                                    :default-sort-direction="orderDir"
                                    striped
                                    bordered
                                  >
                                  <template>
                                    <b-table-column field="planning_code" label="P" cell-class="w-8 vertical-center" centered sortable>
                                      <template v-slot:header="">
                                        {{ $t('code') }}
                                      </template>
                                      <template v-slot="props">
                                        {{ props.row.code }}
                                      </template>
                                    </b-table-column>
                                    <b-table-column field="name" label="Nombre" cell-class="w-8 vertical-center" centered sortable>
                                      <template v-slot:header="">
                                        {{ $t('name') }}
                                      </template>
                                      <template v-slot="props">
                                        {{ props.row.name }}
                                      </template>
                                    </b-table-column>

                                  </template>
                                  </b-table>
                                </template>
                              </div>
                            </div>
                            </b-collapse>
                          </div>
                        </template>

                        </b-table>
                      </template>
                    </div>
                  </div>
                  </b-collapse>
                </div>
              </template>
            </b-table>
          </section>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
// import Form from "vform";
import axios from "axios";
import ImportEntities from './import-entities'
import {mapGetters} from "vuex";
export default {
  name: "project-issue-type", // classes-types-disciplines-origins

  components: {
    ImportEntities
  },

  data: () => ({
    data: [],
    datatable: null,
    form: {
      code: "",
      name: "",
      description: ""
    },
    formProvider: {
      id: null,
      name: null,
      code: null,
      cif: null
    },
    isSupply: false,
    parent_id: null,

    newData: {},
    create: false,
    draggable: false,
    isLoading: true,
    autocomplete: null,
    page: 1,
    perPage: 15,
    total: 0,
    orderField: "name",
    orderDir: "asc",

    storeProviders: [],
    isAddProvider: false,
    currentTypeAddProviderId: null,


    showFormImportEntities: false, // used in import entities



  }),
  computed: {
      ...mapGetters({
        company: "app/company",
      }),
  },
  watch: {
    isSupply: function (val) {
      if (val) {
        this.form.name = 'suministro';
      } else {
        this.form.name = '';
      }
    }
  },
  props: {
    projectId: { type: Number, required: true },
    entity_type: { type: String, default: 'project' },
    option: { type: String, default: 'disciplines' }, // classes | types | disciplines | origins
  },

  mounted() {
    this.getProjectIssueCTDO();
    this.getProviders();
  },

  methods: {
    async addRow(rowParent = null) {
      // this.reload();
      // this.getProjectIssueCTDO();


      if (rowParent) {
        this.parent_id = rowParent.id;
      }

      if (this.create) {
        this.create = false;
        this.form.code = "";
        this.form.name = "";
        this.form.description = "";
        this.isSupply = false;
        this.parent_id = null;
      } else {
        this.create = true;
      }
    },
    saveNewProvider() {
      this.isAddProvider = false;
      const url = "/api/v2/issue-type-supply-provider/" + this.currentTypeAddProviderId;
      const data = {
          store_provider_id: this.formProvider.id
        };
      axios.post(url, data).then(response => {

        if (response.data.success) {
          this.$notify.success("assignment_insert_success");
        } else {
          this.$notify.error("error_saving");
        }
      }).catch(error => {
        this.$notify.error("error_saving");
        console.log(error);
      }).finally(() => {
        this.isAddProvider = false;
        this.formProvider = {
          id: null,
          name: null,
          code: null,
          cif: null
        };

        this.reload();

      });
    },
    updateFormProviderByName() {
        const provider = this.storeProviders.find(provider => provider.id === this.formProvider.id);
        this.formProvider.name = provider.name;
        this.formProvider.code = provider.code;
        this.formProvider.cif = provider.cif;
      },

    addProvider(row) {
      this.isAddProvider = !this.isAddProvider;
      this.currentTypeAddProviderId = row.id;

    },

    cancelAdProvider() {
      this.isAddProvider = false;
      this.currentTypeAddProviderId = null;
    },

    getProviders() {
      const url = "/api/v2/providers";
      axios.get(url, {
        params: {
          company_id: this.company.id
        }
      }).then(response => {
          this.storeProviders = response.data.providers;
      }).catch(error => {
        this.$notify.error("error_loading_data");
        console.log(error);
      });
    },

    async getProjectIssueCTDO() {
      this.isLoading = true;
      let params = {
        // order_dir: this.orderDir,
        // order_field: this.orderField,
        entity_type: this.entity_type, // en este momento no usado en backend
      };

      let endpoint = 'issue-' + this.option // issue-classes | issue-types | issue-disciplines | issue-origins
      const { data } = await axios.get(
        "/api/v2/project/" + this.projectId + "/" + endpoint,
        { params: params }
      );
      if (data && data.success) {
        this.data = data.data;
        this.total = data.data ? data.data.length : 0;
      } else {
        this.$notify.error("error_loading_data");
      }
      this.isLoading = false;
    },

    reload() {
      this.getProjectIssueCTDO();
      this.getProviders();
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    onPageChange(page) {
      this.page = page;
      this.reload();
    },

    onSort(field, order) {
      this.orderField = field;
      this.orderDir = order;
      this.reload();
    },

    editRow(row) {
      this.newData = row
      this.$nextTick(() => {
        let refName = `fieldName${row.id}`
        this.$refs[refName].$el.focus()
      });
    },

    async saveType() {
      if (this.newData.code == '') {
        this.$notify.error(this.$t('empty_field_required', ['code']))
        return
      }

      if (this.newData.name == '') {
        this.$notify.error(this.$t('empty_field_required', ['nombre']))
        return
      }
      let endpoint = 'issue-' + this.option.slice(0, -1) // issue-class | issue-type | issue-discipline | issue-origin (removes 's' for singular)
      endpoint = (endpoint == 'issue-classe') ? 'issue-class' : endpoint; // corregir 'issue-classe' a 'issue-class'
      const { data } = await axios.post("/api/v2/" + endpoint + "/" + this.newData.id, {
        code: this.newData.code,
        name: this.newData.name,
        is_supply: this.isSupply,
        parent_id: this.parent_id,
        description: this.newData.description,
        entity_type: this.entity_type,
      })

      if (data && data.success) {
        // this.getProjectIssueCTDO()
        let index = this.data.findIndex(x => x.id === this.newData.id);
        if( index !== -1 )
          this.data[index] = this.newData
        // else // en este caso se añadiria al array
        this.newData = {}

        this.$notify.success('success_editing')

      } else if(data && data.msg) {
        this.$notify.error('no_access_permissions')
      } else {
        this.$notify.error('error_saving')
      }
    },

    async removeRow(row) {
      this.$dialog.confirm({
        title: this.$t("delete_type"),
        message: this.$t("delete_confirm_text", ['('+row.code+')', row.name]),
        confirmText: this.$t("delete"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          this.isLoading = true;
          let endpoint = 'issue-' + this.option.slice(0, -1) // issue-class | issue-discipline | issue-origin (removes 's' for singular)
          endpoint = (endpoint == 'issue-classe') ? 'issue-class' : endpoint; // corregir 'issue-classe' a 'issue-class'
          let url = "/api/v2/project/" + this.projectId + "/" + endpoint + "/" + row.id;
          const { data } = await axios.delete(url);

          if (data.success) {
            this.$toast.open({
              message: this.$t("removed_successfully"),
              type: "is-success",
              position: "is-top-right"
            });

            await this.sleep(1000);
            this.isLoading = false;

            // let index = this.data.findIndex(x => x.id === this.newData.id);
            // if( index !== -1 )
            //   this.data.splice(index, 1);

            this.reload();
          } else {
            this.isLoading = false;
            // Error
            this.$toast.open({
              message: data.error || this.$t("remove_error"),
              type: "is-danger",
              position: "is-top-right"
            });
          }
        }
      });
    },

    async changeRow() {
      this.isLoading = true;

      let params = {
        code: this.form.code,
        name: this.form.name,
        description: this.form.description,
        parent_id: this.parent_id,
        entity_type: this.entity_type,
        is_supply: this.isSupply
      };

      let endpoint = 'issue-' + this.option.slice(0, -1) // issue-class | issue-discipline | issue-origin (removes 's' for singular)
      endpoint = (endpoint == 'issue-classe') ? 'issue-class' : endpoint; // corregir 'issue-classe' a 'issue-class'
      let url = "/api/v2/project/" + this.projectId + "/" + endpoint;
      axios({
        method: "post",
        url: url,
        data: params
      })
        .then(response => {
          this.$toast.open({
            message: this.$t("save_successful"),
            type: "is-success",
            position: "is-top-right"
          });

          this.create = false;
          this.form.code = "";
          this.form.name = "";
          this.form.description = "";
          this.isSupply = false;
          this.reload();
        })
        .catch(error => {
          //Se produjo un error
          this.isLoading = false;
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("measurement_unit_change_fail"),
            type: "is-danger",
            position: "is-top-right"
          });
        });
    },


    canDelete(data) {
      return (
        (data.activities && data.activities.length > 0) ||
        (data.issues && data.issues.length > 0)
      );
    }
  }
};
</script>
